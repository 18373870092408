
  import {
    assignContractToMeById,
    assign_contract,
    getContractItemById,
    delete_contract,
    generate_bill,
    ask_reopen_contract,
    ask_close_contract,
    reset_contract,
    add_download_contract_template_log,
    make_contract_in_force,
    make_contract_close,
    change_contract_buline,
    manage_entity,
    get_contract_template_list,
    edit_contract,
    get_lark_contract_temps,
    new_lark_contract,
    delete_lark_contract,
    sync_lark_contract,
  } from '@/api/erp-contracts';
  import { get_company_related_items, edit_company_finance_info_apply } from '@/api/crm-customer';
  import { isValidAccounts, required, short6 } from '@/utils/validate';
  import { computed, defineComponent, reactive, ref } from 'vue';
  import type { BasicTableProps } from '@/components/WeTable/types/table';
  import { useContract } from './hooks/useContract';
  import { useUser } from '@/hooks/useUser';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { useUtils } from '@/hooks/useUtils';
  import { usePay } from '../pays/hooks/usePay';
  import { appStore } from '@/store/modules/app';
  import { DialogConfig, DialogFormItem } from '@/components/WeDialog/types';
  import { isDev } from '@/utils/const';
  import { copyObjectToReactive, mapItems, isEmptyData, findDialogInput, formatNumberByNumeral } from '@/utils/common';
  import { useJump } from '@/hooks/useJump';
  import { useDebounceFn } from '@vueuse/core';
  import { handle_flow_action } from '@/api/crm-customerTrack';
  import omit from 'lodash/omit';
  import InlineList from '@/views/crm/components/InlineList.vue';
  import EmailCards from '../components/EmailCards.vue';
  import EntityList from '../components/EntityList.vue';
  import ContractConfig from '../components/ContractConfig.vue';
  import LegalAffairs from '../components/LegalAffairs.vue';
  import { useInvoice } from '../invoices/hooks/useInvoice';
  import { formatTime } from '@/utils/time';
  import { get_company_list } from '@/api/crm-customer';
  import { sortBy } from 'lodash-es';
  export default defineComponent({
    name: 'ERPContractDetail',
    props: {
      contractId: {
        type: [String, Number] as PropType<any>,
        default: undefined,
      },
    },
    components: {
      EntityList,
      ContractConfig,
      EmailCards,
      InlineList,
      LegalAffairs,
    },
    setup() {
      const formSplit = ref<Nullable<FormActionType>>(null);
      const contract = reactive<Recordable>({});
      const archiveList = ref<Recordable[]>([]);
      const templateList = ref<Recordable[]>([]);
      const similarity_companys = ref<Recordable[]>([]);
      const lark_contracts = ref<Recordable[]>([]);
      const bulineSplitList = ref<Recordable[]>([]);
      const { jumpToCompany, jumpToOpp, jumpToCfiDetail } = useJump();
      const { editInvoice } = useInvoice();

      const {
        user,
        isAdmin,
        isFinance,
        isGod,
        hasPermission,
        isAnalysis,
        isLegalHead,
        isTeamHead,
        isBusinessHead,
        isLegal,
      } = useUser();
      const {
        apiRoot,
        model,
        teamMap,
        appInfo,
        getAllTeams,
        getModelItems,
        countryList,
        teamList,
        userMap,
        getMyTeamList,
        getTeamUsers,
        getTeamUserDialogChange,
        getTfItems,
        getGroupUsers,
        getTeamGroup,
        weCompanyMap,
      } = useAppInfo();
      const { initContractList, editContract, required_items } = useContract();
      const { jumpToContract, jumpToBill, jumpToPay } = useJump();
      const { addPrepay } = usePay();
      const { downloadUrl, showJson } = useUtils();
      const company = ref<Recordable>({});
      const show_force_effect = computed(() => {
        if (isEmptyData(contract)) {
          return false;
        }
        return required_items.value.every((item) => !isEmptyData(contract[item.key]));
      });
      return {
        formSplit,
        appInfo,
        user,
        isAdmin,
        isLegal,
        isAnalysis,
        isLegalHead,
        isBusinessHead,
        isGod,
        isFinance,
        isTeamHead,
        model,
        contract,
        company,
        teamMap,
        getAllTeams,
        teamList,
        userMap,
        countryList,
        getModelItems,
        hasPermission,
        initContractList,
        editContract,
        downloadUrl,
        showJson,
        archiveList,
        templateList,
        jumpToBill,
        addPrepay,
        jumpToContract,
        jumpToCompany,
        jumpToPay,
        getMyTeamList,
        getTeamUsers,
        getTeamUserDialogChange,
        getTfItems,
        similarity_companys,
        getGroupUsers,
        jumpToOpp,
        show_force_effect,
        lark_contracts,
        apiRoot,
        bulineSplitList,
        getTeamGroup,
        editInvoice,
        jumpToCfiDetail,
        weCompanyMap,
      };
    },
    data() {
      return {
        show_edit_bulines: false,
        contractDetailLoading: false,
        readOnly: false,
        rules: { isValidAccounts },
        loadingAccounts: false,
        company_name: '',
        archiveConfig: {
          tabConfig: undefined,
          useSearchForm: false,
          showTableHeader: false,
          columns: [
            {
              title: '名称',
              dataIndex: 'archive_name',
              slots: { customRender: 'name' },
            },
            { title: '附件', dataIndex: 'files', slots: { customRender: 'files' } },
            { title: '位置', dataIndex: 'location' },
            { title: '类型', dataIndex: 'archive_type_str' },
            { title: '已确认', dataIndex: 'is_confirmed_str' },
            {
              title: '操作',
              dataIndex: 'action',
              slots: { customRender: 'action' },
              flag: 'ACTION' as any,
            },
          ],
        },
        templateDialog: false,
        templateConfig: {
          tabConfig: undefined,
          useSearchForm: false,
          showTableHeader: false,
          columns: [
            { title: 'Folder 1', dataIndex: 'folder1', merge: true },
            { title: 'Folder 2', dataIndex: 'folder2', merge: true },
            { title: 'Template', dataIndex: 'name', slots: { customRender: 'name' } },
            { title: 'Note', dataIndex: 'note' },
          ],
        },
        templateDialogConfig: {},
        tracePage: 'Contract Detail',
        bulinesTableConfig: {
          tabConfig: undefined,
          useSearchForm: false,
          showTableHeader: false,
          columns: [
            { title: 'Buline', dataIndex: 'buline_name' },
            { title: 'Team', dataIndex: 'team_id', slots: { customRender: 'team_id' } },
            { title: 'AM', dataIndex: 'am_id', slots: { customRender: 'am_id' } },
            { title: '操作', dataIndex: 'id', slots: { customRender: 'action' } },
          ],
        },
      };
    },
    created() {
      this.initData();
    },
    computed: {
      larkContractConfig(): BasicTableProps {
        return {
          title: '飞书合同',
          useSearchForm: false,
          columns: [
            { title: '飞书合同编号', dataIndex: 'contract_number', slots: { customRender: 'contract_number' } },
            { title: '创建时间', dataIndex: 'create_time' },
            { title: '操作', dataIndex: 'action', flag: 'ACTION', slots: { customRender: 'action' } },
          ],
        };
      },
      subContractConfig(): BasicTableProps {
        return {
          tabConfig: undefined,
          useSearchForm: false,
          columns: [
            { title: 'Team', dataIndex: 'rd_team_name' },
            { title: '名称', dataIndex: 'name', slots: { customRender: 'name' } },
            {
              title: '状态',
              dataIndex: 'rd_state_name',
              colorField: 'rd_state_color',
              slots: { customRender: 'rd_state_name' },
            },
            { title: '属性', dataIndex: 'rd_property_name' },
            { title: '所属人', dataIndex: 'rd_owner_name' },
            { title: 'AM', dataIndex: 'rd_am_name' },
            { title: '业务线', dataIndex: 'rd_bulines' },
          ],
        };
      },
      prepayConfig(): BasicTableProps {
        return {
          useSearchForm: false,
          immediate: false,
          columns: [
            { title: '名称', dataIndex: 'name', slots: { customRender: 'name' } },
            {
              title: '状态',
              dataIndex: 'rd_state_name',
              colorField: 'rd_state_color',
              slots: { customRender: 'rd_state_name' },
            },
            { title: '计划付款日期', dataIndex: 'rd_plan_pay_time' },
            { title: '实际付款日期', dataIndex: 'rd_pay_time' },
            { title: '金额', dataIndex: 'pay_money' },
            { title: '创建时间', dataIndex: 'create_time' },
          ],
        };
      },
      showContract(): boolean {
        return !!this.contract && !this.contract.is_deleted;
      },
      isBusinessItem(): boolean {
        return this.contract && this.contract.category === this.model.Contract.CATEGORY.BUSINESS;
      },
      isSupply(): boolean {
        return this.contract.is_supply!;
      },
      isDemand(): boolean {
        return this.contract.is_demand!;
      },
      isPurchaseItem(): boolean {
        return this.contract && this.contract.category === this.model.Contract.CATEGORY.PURCHASE;
      },
      isBackupItem(): boolean {
        return this.contract && this.contract.category === this.model.Contract.CATEGORY.BACKUP;
      },

      isPrepay(): boolean | undefined {
        return (
          this.isBusinessItem &&
          this.contract.contract_property === this.model.Contract.CONTRACT_PROPERTY.PREPAY &&
          this.contract.is_supply
        );
      },

      isPubLogic(): boolean {
        return this.contract && this.contract.logic_type === this.model.Contract.LOGIC_TYPE.PUB;
      },
      isScalerLogic(): boolean {
        return this.contract && this.contract.logic_type === this.model.Contract.LOGIC_TYPE.TAURUS;
      },
      isCloudLogic(): boolean {
        return (
          this.contract &&
          [
            this.model.Contract.LOGIC_TYPE.CLOUD,
            this.model.Contract.LOGIC_TYPE.EE,
            this.model.Contract.LOGIC_TYPE.FEISHU,
          ].includes(this.contract.logic_type)
        );
      },
      isMediaLogic(): boolean {
        return this.contract && this.contract.logic_type === this.model.Contract.LOGIC_TYPE.MEDIA;
      },

      isInForce(): boolean {
        return this.contract && this.contract.state_value === this.model.ContractState.STATE.IN_FORCE;
      },
      isOversea(): boolean {
        return this.contract && this.contract.is_china === false;
      },
      isMine(): boolean {
        const c = this.contract;
        return [c.owner_id, c.am_id].includes(this.user.id);
      },
      isAsking(): boolean {
        const isAskingClose =
          this.contract.state_value === this.model.ContractState.STATE.LEGAL_TERMINATING_CONTRACT &&
          this.contract.state_status === this.model.FlowState.STATUS.OPEN;
        const isAskingReopen = this.contract.state_value === this.model.ContractState.STATE.REOPEN;
        return isAskingClose || isAskingReopen;
      },

      isMain(): boolean | undefined {
        return this.contract && this.contract.is_main_contract;
      },
      isSub(): boolean | undefined {
        return this.contract && this.contract.is_sub_contract;
      },
      canSendLegalEmail(): any {
        return (
          !this.isSupply &&
          (((this.isCloudLogic || this.isMediaLogic) && this.hasPermission('RoleLegal')) || this.isGod)
        );
      },
      canAddPurchasePay(): any {
        return (
          (this.isMine || this.isGod || isDev) &&
          this.isPurchaseItem &&
          this.contract.currency_id &&
          this.contract.company_id &&
          this.contract.we_company_id
        );
      },

      canAddPrepay(): any {
        const hasFields = this.contract.currency_id && this.contract.company_id && this.contract.we_company_id;
        return (this.isMine || this.isGod || isDev) && hasFields && this.isPrepay && !this.isMain;
      },
      canReset(): boolean {
        return !this.isBackupItem && (this.isGod || this.isLegal || isDev);
      },
      canMakeInforce(): boolean {
        return (
          ![this.model.ContractState.STATE.CLOSED, this.model.ContractState.STATE.IN_FORCE].includes(
            this.contract.state_value
          ) &&
          (this.isAdmin || this.isLegal || isDev) &&
          !this.isSub
        );
      },
      canTerminate(): boolean {
        return (this.isAdmin || this.isLegalHead || isDev) && !this.isSub;
      },
      canAskReopen(): any {
        return !this.isAsking && this.contract.is_finished && (this.isMine || isDev) && !this.isSub;
      },
      canAskClose(): boolean {
        return !this.isAsking && !this.contract.is_closed && (this.isMine || isDev) && !this.isSub;
      },
      canGenBill(): boolean {
        return (
          this.isBusinessItem &&
          !this.isMain &&
          (this.isAdmin || this.isMine || this.isTeamHead(this.contract.team_id) || this.isFinance || isDev)
        );
      },
      canAssign(): boolean {
        return this.isMine || this.isTeamHead(this.contract.team_id) || this.isAdmin || this.isAnalysis || isDev;
      },
      canAssignToMe(): any {
        if (this.contract.is_sub_contract) {
          return false;
        }
        const state = this.contract.flow!.current_state;
        if (state.owner.id !== this.user.id) {
          if (this.isAnalysis && state.role === this.model.FlowState.ROLE.FBP) {
            return true;
          }
          if (this.isLegal && state.role === this.model.FlowState.ROLE.LEGAL) {
            return true;
          }
        }
        return isDev;
      },
      canEdit(): boolean {
        return this.isMine || this.isTeamHead(this.contract.team_id) || this.isAdmin || this.isFinance || this.isLegal;
      },
      canSplit(): boolean {
        let hasSplitConfig = false;
        if (this.contract.team_id) {
          const split_team_ids = this.teamMap[this.contract.team_id!].split_team_ids || [];
          hasSplitConfig = split_team_ids.length > 0;
        }
        return hasSplitConfig && this.canEdit && !this.contract.is_closed;
      },
      moreActions(): Recordable[] {
        const vm = this;
        if (this.hasPermission('RoleFinanceDataViewer') && !this.isGod) {
          return [{ show: true, label: '修改记录', onClick: () => vm.$showChange(vm.contract, 'contract') }];
        }

        const ret = [
          { show: vm.canAddPrepay, label: '添加预付款', onClick: () => vm.addPrepay(vm.contract, vm) },

          { show: vm.canAskReopen, label: '重启流程', onClick: () => vm.askReopenContract() },
          { show: vm.canAskClose, label: '终止合同', onClick: () => vm.askCloseContract() },
          { show: vm.canMakeInforce, label: '强制生效', onClick: () => vm.makeContractInForce() },
          { show: vm.canTerminate, label: '强制关闭', onClick: () => vm.makeContractClose() },
          { show: vm.canReset, label: '重置', onClick: () => vm.resetContract() },
          { show: vm.canAssign, label: '分配', onClick: () => vm.assignContract() },
          { show: vm.canAssignToMe, label: '认领', onClick: () => vm.assignToMe() },

          { show: (vm.isLegal && !vm.isSub) || vm.isGod, label: '添加档案', onClick: () => vm.editArchive('add') },
          {
            show: isEmptyData(vm.contract.bill_list) && vm.contract.company.company_group_id,
            label: '修改主体',
            onClick: () => vm.editCompany(),
          },
          {
            show: this.isGod || this.isLegal || this.isMine || this.isFinance,
            label: '编辑名称备注',
            onClick: vm.editNameNote,
          },
          { show: vm.canGenBill, label: '生成账单', onClick: () => vm.generateBill(false) },
          { show: vm.canGenBill, label: '生成返点账单', onClick: () => vm.generateBill(true) },
          { show: vm.canSendLegalEmail, label: '生成&发送法务函', onClick: () => vm.show_legal_affairs() },

          {
            show: vm.isFinance || this.isMine || this.isGod || this.isPrepay,
            label: '添加发票',
            onClick: () => vm.addInvoice(),
          },

          { show: vm.isAdmin || vm.isAnalysis, label: '删除', onClick: () => vm.deleteContract() },
          { show: true, label: '修改记录', onClick: () => vm.$showChange(vm.contract, 'contract') },
          { show: vm.isGod || isDev, label: 'Json', onClick: () => vm.showContractJson() },
          { show: vm.isGod || isDev, label: '跳转HW3', onClick: () => vm.jumpToHW3() },
        ];
        return ret.filter((x) => x.show);
      },
      linkPayListConfig(): BasicTableProps {
        return {
          tabConfig: undefined,
          useSearchForm: false,
          columns: [
            { title: '名称', dataIndex: 'name', slots: { customRender: 'name' } },
            { title: '归属人', dataIndex: 'rd_owner_name' },
            {
              title: '状态',
              dataIndex: 'rd_state_name',
              colorField: 'rd_state_color',
              slots: { customRender: 'rd_state_name' },
            },
            { title: '类型', dataIndex: 'category_name' },
            { title: '计划付款日期', dataIndex: 'plan_pay_time' },
            { title: '实际付款日期', dataIndex: 'pay_time' },
            {
              title: '付款金额',
              dataIndex: 'pay_money',
              round: 2,
              hiddenFilter: true,
              componentType: 'we-number-range',
              numberFormat: '0,0.00',
            },
            { title: '创建时间', dataIndex: 'create_time' },
          ],
        };
      },
      cfiConfig(): BasicTableProps {
        let columns: any[] = [
          { title: '申请', dataIndex: 'name', componentType: 'a-input', slots: { customRender: 'name' } },
          { title: '状态', dataIndex: 'rd_state_name', colorField: 'rd_state_color', componentType: 'a-select' },
        ];
        if (this.isOversea) {
          columns = columns.concat([
            { title: '州', dataIndex: 'country_state' },
            { title: '公司地址', dataIndex: 'invoice_address' },
            { title: '银行名称', dataIndex: 'bank_name' },
            { title: '银行账号', dataIndex: 'bank_account_number' },
            { title: '银行地址', dataIndex: 'bank_address' },
            { title: 'Swift Code', dataIndex: 'bank_swift_code' },
          ]);
        } else {
          columns = columns.concat([
            { title: '纳税人识别号', dataIndex: 'tax_number' },
            { title: '公司地址', dataIndex: 'invoice_address' },
            { title: '公司电话', dataIndex: 'invoice_phone' },
            { title: '开户行', dataIndex: 'bank_name' },
            { title: '开户行账号', dataIndex: 'bank_account_number' },
          ]);
        }
        columns.push({ title: '来自其它合同', dataIndex: 'is_from_other' });
        return {
          tabConfig: undefined,
          useSearchForm: false,
          columns: columns,
        };
      },
      cfiButtonConfig() {
        const existsApply = this.contract.company_finance_info_apply_list.find(
          (x) => !['完成', '申请失败'].includes(x.rd_state_name) && x.contract_id === this.contract.id
        );
        if (existsApply || !this.canEdit) {
          return [];
        } else {
          return [
            {
              title: '新建申请',
              click: this.addCfi,
              show: this.isGod || this.isLegal || this.isMine || this.isFinance,
            },
          ];
        }
      },
      billConfig(): BasicTableProps {
        return {
          useSearchForm: false,
          immediate: false,
          columns: [
            {
              title: '属性',
              dataIndex: 'rd_property',
              componentType: 'a-select',
            },
            { title: '部门', dataIndex: 'rd_team_short_name' },
            { title: '名称', dataIndex: 'name', slots: { customRender: 'name' } },
            {
              title: '状态',
              dataIndex: 'rd_state_name',
              colorField: 'rd_state_color',
              slots: { customRender: 'rd_state_name' },
            },
            {
              title: '暂估金额',
              dataIndex: 'finance_estimate_money',
              componentType: 'a-input',
            },
            {
              title: '发票金额',
              dataIndex: 'finance_invoice_money',
              componentType: 'a-input',
            },
            {
              title: '税额',
              dataIndex: 'tax_money',
              componentType: 'a-input',
            },
            { title: '归属人', dataIndex: 'rd_owner_name' },
            {
              title: '对账进度',
              dataIndex: 'rd_bill_time_status',
              slots: { customRender: 'rd_bill_time_status' },
              colorField: 'rd_bill_time_status_color',
            },
            {
              title: this.isSupply ? '付款进度' : '收款进度',
              dataIndex: 'rd_pay_time_status',
              slots: { customRender: 'rd_pay_time_status' },
              colorField: 'rd_pay_time_status_color',
            },
          ],
          initItem: (item) => (item.rd_team_short_name = this.teamMap[item.team_id].name),
        };
      },
      riskCount() {
        const riskItems = [
          {
            tag: '自身风险',
            num: this.company.qcc_info?.self_risk_num,
          },
          {
            tag: '关联风险',
            num: this.company.qcc_info?.linked_risk_num,
          },
          {
            tag: '历史风险',
            num: this.company.qcc_info?.history_risk_num,
          },
          {
            tag: '提示信息',
            num: this.company.qcc_info?.self_tip_num || 0,
          },
        ];
        if (this.company.is_attention) {
          riskItems.push({
            tag: '敏感舆情',
            num: this.company.qcc_info?.news_num,
          });
        }
        return riskItems;
      },
      teamOptions() {
        const teams = this.isGod ? this.teamList : this.getTeamGroup(this.contract.team_id)!.team_list;
        return teams
          .filter((t) => !t.is_history)
          .map((t) => ({
            text: t.name,
            value: t.id,
          }));
      },
      showAutoInvoice() {
        return (
          this.contract.is_demand &&
          this.weCompanyMap[this.contract.we_company_id].region_type === this.model.WeCompany.REGION_TYPE.GLOBAL &&
          false
        );
      },
    },
    methods: {
      formatNumberByNumeral,
      useDebounceFn,
      isEmptyData,
      jumpToHW3() {
        const url = `${window.location.origin}/business/hw3/contract/detail/${this.contract.id}`;
        window.open(url, '_blank');
      },
      show_legal_affairs() {
        (this.$refs.LegalAffairsRef as any).gen_legal_affairs();
      },
      async catchFlowFlag(flag, params) {
        const vm = this;
        if (flag === 'FLAG_EDIT_CONTRACT') {
          this.editContract(this.contract, this.afterEditContract);
        } else if (flag === 'FLAG_ASKING_TEMPLATE') {
          const dialogConfig = {
            title: '提示',
            width: '600px',
            blocks: [{ type: 'tip', value: '确定继续操作?' }],
            callback: () => {
              handle_flow_action({ ...params }).then((res) => {
                if (res.error_message) {
                  this.$showAlert('提示', res.error_message);
                } else {
                  this.afterEditContract(res);
                }
              });
            },
          };
          if (vm.contract.template_type === vm.model.Contract.TEMPLATE_TYPE.OUR_TEMPLATE) {
            get_contract_template_list({}).then((res) => {
              let curType = '';
              if (this.contract.category === this.model.Contract.CATEGORY.PURCHASE) {
                curType = 'Purchase';
              } else if (this.contract.category === this.model.Contract.CATEGORY.BACKUP) {
                curType = 'Backup';
              } else {
                curType = this.contract.is_supply ? 'Supply' : 'Demand';
              }

              const ctList = res.filter(
                (x) => x.is_active && x.team_id === this.contract.team_id && x.contract_type === curType
              );
              if (ctList.length) {
                ctList.sort((a, b) => a.folder2.localeCompare(b.folder2));
                ctList.sort((a, b) => a.folder1.localeCompare(b.folder1));
                this.templateList = ctList;
                this.templateDialogConfig = dialogConfig;
                this.templateDialog = true;
              } else {
                vm.$showDialog(dialogConfig);
              }
            });
          } else {
            vm.$showDialog(dialogConfig);
          }
        } else if (flag === 'FLAG_NEW_LARK_CONTRACT') {
          this.createFSContract(params);
        }
      },
      download_lark_contract_scan(record) {
        const url = `${this.apiRoot}/download_lark_contract_scan?id=${record.id}`;
        window.open(url);
      },
      delete_lark_contract(record) {
        const params = {
          contract_id: record.contract_id,
          lark_contract_id: record.lark_contract_id,
        };
        delete_lark_contract(params).then(() => {
          this.$message.success('删除成功');
          this.initData();
        });
      },
      sync_lark_contract(record) {
        const params = {
          lark_contract_id: record.lark_contract_id,
        };
        sync_lark_contract(params).then(() => {
          this.$message.success('同步成功');
          this.initData();
        });
      },
      async createFSContract(flow_param) {
        let tempList = await get_lark_contract_temps({ contract_id: Number(this.contractId) });
        console.log(tempList);
        const catItems = tempList.map((item) => ({ id: item.category_id, name: item.category_name }));

        const inputs = [
          {
            width: 24,
            type: 'select',
            result: 'type',
            label: '合同类型',
            items: [
              { name: '模版合同', id: 'template' },
              { name: '文件合同', id: 'file' },
            ],
          },
          {
            width: 24,
            type: 'select',
            result: 'category_id',
            label: '类别',
            items: catItems,
            rules: [required()],
            change: (result, _input, config) => {
              if (result.type === 'template') {
                const temp_id_input = findDialogInput(config, 'temp_id');
                const find_item = tempList.find((item) => item.category_id === result.category_id);
                temp_id_input.items = find_item.templates.map((m) => ({ id: m.temp_id, name: m.name }));
                result.temp_id = null;
              }
            },
          },
          {
            width: 24,
            type: 'select',
            result: 'temp_id',
            label: '模版名称',
            rules: [required()],
            items: [],
            show: (result) => result.type === 'template',
          },
          {
            width: 24,
            type: 'localFile',
            result: 'file_id',
            label: '合同文件',
            action: '/api/v1/upload_lark_contract_file',
            maxCount: 1,
            show: (result) => result.type === 'file',
          },
          {
            width: 24,
            type: 'number',
            result: 'amount',
            label: '合同金额',
            rules: [required()],
          },
          {
            width: 24,
            type: 'select',
            result: 'property_type_code',
            label: '计价方式编码',
            init: 0,
            rules: [required()],
            items: [
              { id: 0, name: '固定总价' },
              { id: 1, name: '不固定总价' },
            ],
          },
          {
            width: 24,
            type: 'select',
            result: 'fixed_validity_code',
            label: '固定期限编码',
            init: 1,
            rules: [required()],
            items: [
              { id: 0, name: '无固定期限' },
              { id: 1, name: '固定期限' },
            ],
          },
        ];
        if (isEmptyData(this.contract.begin_time)) {
          inputs.push({
            type: 'date',
            label: '开始时间',
            result: 'begin_time',
            width: 24,
            rules: [required()],
          });
        }
        if (isEmptyData(this.contract.end_time)) {
          inputs.push({
            type: 'date',
            label: '结束时间',
            result: 'end_time',
            width: 24,
            rules: [required()],
          });
        }
        const dialog = {
          title: '创建飞书合同',
          width: '900px',
          labelCol: '120px',
          blocks: [{ type: 'input', value: inputs }],
          callback: (result) => {
            if (result.type === 'file') {
              if (isEmptyData(result.file_id)) {
                this.$message.error('请上传合同文件');
                return;
              }
              result.file_id = result.file_id.file_id;
            }
            new_lark_contract({
              action_id: flow_param.action_id,
              contract_id: Number(this.contractId),
              ...omit(result, ['category_name']),
            })
              .then((res) => {
                if (res.error_message) {
                  this.$message.error(res.error_message);
                } else {
                  this.$message.success('创建成功');
                  this.getContractDetail(Number(this.contractId));
                  this.$showAlert('飞书合同', `<a href=${res.lark_contract_url} target="_blank">跳转到合同</a>`);
                }
              })
              .catch(() => {
                this.$message.error('创建失败');
              })
              .finally(() => {
                appStore.SET_WE_DIALOG(null);
              });
          },
        };
        this.$showDialog(dialog);
        return inputs;
      },
      async initData() {
        if (!this.contractId) {
          this.$router.go(-1);
          return;
        }
        await this.getContractDetail(Number(this.contractId));
      },
      getContractDetail(id) {
        this.contractDetailLoading = true;
        getContractItemById({ contract_id: id }).then((res) => {
          this.refreshContractRes(res);
          this.initRelatedItems();
          this.contractDetailLoading = false;
          get_company_related_items({ company_id: Number(this.contract.company_id) }).then((res) => {
            this.company = res.group_company_list.find((g) => g.id === this.contract.company_id) || Object();
            this.company.group = res.group_company_list;
          });
        });
      },
      refreshContractRes(res) {
        const contractList = [res.contract];
        this.initContractList(contractList);
        this.lark_contracts = res.contract.lark_contracts;
        copyObjectToReactive(this.contract, contractList[0]);
        document.title = this.contract!.name as string;
        this.bulineSplitList = this.contract.split_list;
      },
      doAfterEditContract(res?) {
        appStore.SET_WE_DIALOG(null);
        appStore.SET_ALERT_CONFIG(null);
        if (res && res === 'delete') {
          this.contract = {};
          appStore.SET_DRAWER_CONFIG(null);
          this.$router.go(-1);
          // 返回列表页
        } else if (!isEmptyData(this.contract)) {
          this.getContractDetail(Number(this.contractId));
        }
      },
      afterEditContract(res) {
        this.doAfterEditContract(res);
      },
      afterEditPay(res) {
        this.afterEditContract(res);
        this.$emit('afterEditContract', res);
        appStore.SET_WE_DIALOG(null);
      },
      closeRefresh(res) {
        appStore.SET_WE_DIALOG(null);
        appStore.SET_ALERT_CONFIG(null);
        this.afterEditContract(res);
        this.initRelatedItems();
      },
      corpNameImg(): string {
        const publicPath = process.env.BASE_URL;
        const qccInfo = this.company.qcc_info;
        if (!this.company.is_linked || isEmptyData(qccInfo)) {
          return `${publicPath}assets/noRiskLevel.svg`;
        } else if (qccInfo.risk_level == 3) {
          return `${publicPath}assets/riskLevelHigh.svg`;
        } else if (qccInfo.risk_level == 2) {
          return `${publicPath}assets/riskLevelCenter.svg`;
        } else {
          return `${publicPath}assets/riskLevelLower.svg`;
        }
      },
      assignToMe() {
        const tip = `Are you sure to assign this contract from ${
          this.contract.flow!.current_state.owner.name
        } to yourself?`;
        const params = { contract_id: this.contract.id };
        this.$showAlert('警告', tip, (_) => {
          assignContractToMeById(params).then((res) => {
            this.closeRefresh(res);
          });
        });
      },
      assignContract() {
        const vm = this;
        const ownerTeamItems = mapItems(this.getMyTeamList(true));
        const amTeamItems = mapItems(this.getMyTeamList(true));
        const ownerItems = mapItems(this.getTeamUsers(this.contract.team_id));
        const amItems = mapItems(this.getTeamUsers(this.contract.am_team_id));
        const ownerChange = vm.getTeamUserDialogChange('team_id', 'owner_id');
        const amChange = vm.getTeamUserDialogChange('am_team_id', 'am_id');
        const trackerItems = mapItems(this.getGroupUsers(this.contract.team_id));
        trackerItems.unshift({ text: '【无】', value: 0 });
        const inputs: DialogFormItem[] = [
          {
            width: 12,
            type: 'auto-select',
            result: 'team_id',
            label: '跟进人部门',
            items: ownerTeamItems,
            change: ownerChange,
          },
          { width: 12, type: 'auto-select', result: 'owner_id', label: '跟进人', items: ownerItems },
        ];

        if (vm.isBusinessItem) {
          inputs.push({
            width: 12,
            type: 'auto-select',
            result: 'am_team_id',
            label: 'AM Team',
            items: amTeamItems,
            change: amChange,
          });
          inputs.push({ width: 12, type: 'auto-select', result: 'am_id', label: 'AM (账单Owner)', items: amItems });
          inputs.push({
            type: 'auto-select',
            result: 'tracker_id',
            label: 'Tracker (合同代跟人)',
            items: trackerItems,
          });
          inputs.push({
            type: 'auto-select',
            result: 'bill_tracker_id',
            label: 'Bill Tracker (账单代跟人)',
            items: trackerItems,
          });

          inputs.push({
            type: 'auto-select',
            result: 'set_bill_owners',
            label: '同时设置合同下所有账单的Owner为合同的AM，注意可能会影响数据归属',
            items: this.getTfItems,
            init: false,
          });
        } else {
          inputs.push({
            width: '24',
            type: 'auto-select',
            result: 'tracker_id',
            label: 'Tracker (合同代跟人)',
            items: trackerItems,
          });
        }

        this.$showDialog({
          title: '分配合同',
          width: '600px',
          init: this.contract,
          layout: 'vertical',
          blocks: [{ type: 'input', value: inputs }],
          callback: (result) => {
            assign_contract({ contract_id: vm.contract.id, ...result }).then((res) => {
              vm.closeRefresh(res);
            });
          },
        });
      },
      askTemplate() {
        this.templateDialog = false;
        this.$showDialog(this.templateDialogConfig as DialogConfig);
      },
      deleteContract() {
        this.$showAlert('警告', 'Are you sure to DELETE this contract?', () => {
          delete_contract({ contract_id: this.contract.id }).then((res) => {
            if (res.status === 500) {
              this.$message.warning(res.error);
            } else {
              this.closeRefresh('delete');
            }
          });
        });
      },
      generateBill(isRebate) {
        const vm = this;
        const inputs: Recordable[] = [
          {
            type: 'month',
            result: 'month',
            label: '月份',
            mode: 'month',
            valueFormat: 'YYYY-MM',
            placeholder: '请选择月份',
          },
        ];

        const isMedia =
          vm.contract.logic_type === vm.model.Contract.LOGIC_TYPE.MEDIA &&
          (vm.contract.logic_detail.includes('BX_Demand') ||
            vm.contract.logic_detail.includes('ML_Supply_Global') ||
            vm.contract.logic_detail.includes('ML_Supply_China') ||
            vm.contract.logic_detail.includes('MB_Demand') ||
            vm.contract.logic_detail.includes('RS_Demand') ||
            vm.contract.logic_detail.includes('PK_Demand'));

        if (isMedia) {
          if (isRebate) {
            const timeSpanItems: any = vm.appInfo.time_span_list
              .filter((x) => [vm.model.TimeSpan.RANGE_TYPE.BY_Q].includes(x.range_type))
              .map((x) => ({ text: x.name, value: x.id }));
            timeSpanItems.sort((a, b) => b.value - a.value);
            inputs.push({ type: 'select', result: 'time_span_id', label: '业务区间', items: timeSpanItems });
          }

          let bulineItems = vm.teamMap[vm.contract.team_id].buline_list.map((buline) => ({
            text: buline.note ? `${buline.name} (${buline.note})` : buline.name,
            value: buline.id,
          }));
          inputs.push({
            type: 'select',
            label: '子账单Buline',
            result: 'buline_ids',
            mode: 'multiple',
            items: bulineItems,
          });
        }

        vm.$showDialog({
          title: '生成账单',
          width: '700px',
          blocks: [{ type: 'input', value: inputs }],
          callback: (result) => {
            generate_bill({ contract_id: this.contract.id, is_rebate: isRebate, ...result }).then((res) => {
              if (res.error) {
                this.$message.error(res.error);
              } else {
                this.jumpToBill(res.bill);
                this.afterEditContract(res);
              }
              appStore.SET_WE_DIALOG(null);
            });
          },
        });
      },
      askReopenContract() {
        const vm = this;
        vm.$showDialog({
          title: 'Ask Reopen Contract',
          width: '600px',
          blocks: [
            { type: 'tip', value: 'Are you sure to ask Legal to reopen this contract?' },
            {
              type: 'note',
              result: 'note',
              label: 'Reason',
              width: 24,
              rules: [
                {
                  required: true,
                  type: 'any',
                  trigger: 'change',
                  message: '请输入备注',
                },
              ],
            },
          ],
          callback: (result) => {
            ask_reopen_contract({ contract_id: vm.contract.id, ...result }).then((res) => {
              vm.closeRefresh(res);
            });
            // vm.traceAction('Ask Reopen Contract');
          },
        });
      },
      askCloseContract() {
        const vm = this;
        vm.$showDialog({
          title: 'Ask Terminate Contract',
          blocks: [
            {
              type: 'tip',
              value:
                'Are you sure to ask <b>Terminate</b> this contract? All in-progress steps will be <b>CANCELED</b>!!!',
            },
            {
              type: 'note',
              result: 'note',
              label: 'Reason',
              rules: [
                {
                  required: true,
                  type: 'any',
                  trigger: 'change',
                  message: '请输入备注',
                },
              ],
            },
          ],
          callback: (result) => {
            ask_close_contract({ contract_id: vm.contract.id, ...result }).then((res) => {
              vm.closeRefresh(res);
            });

            // vm.traceAction('Ask Terminate Contract');
          },
        });
      },
      resetContract() {
        this.$showAlert('警告', 'Are you sure to Reset this contract?', () => {
          reset_contract({ contract_id: this.contract.id }).then((res) => {
            this.closeRefresh(res);
          });
        });
      },
      makeContractInForce() {
        if (!this.show_force_effect) {
          this.$message.error('请填写合同信息里的必填项');
          return;
        }
        this.$showAlert('警告', 'Are you sure to make this contract In Force?', () => {
          make_contract_in_force({ contract_id: this.contract.id }).then((res) => {
            this.closeRefresh(res);
          });
        });
      },
      makeContractClose() {
        this.$showAlert('警告', 'Are you sure to make this contract Closed?', () => {
          make_contract_close({ contract_id: this.contract.id }).then((res) => {
            this.closeRefresh(res);
          });
        });
      },
      showContractJson() {
        this.showJson(this.contract);
      },
      editNameNote() {
        const inputs = [
          {
            type: 'text',
            label: '名称备注',
            result: 'name_note',
            init: this.contract.name_note,
            rules: [short6],
            width: 24,
          },
        ];
        this.$showDialog({
          title: '设置合同名称备注',
          width: '300px',
          labelCol: '80px',
          blocks: [{ type: 'input', value: inputs }],
          callback: (result) => {
            let params = { __meta: { edit_mode: 'edit', contract_id: this.contract.id }, ...result };
            edit_contract(params).then((result) => {
              this.afterEditContract(result);
            });
          },
        });
      },
      initRelatedItems() {
        this.$nextTick(() => {
          this.archiveList = this.contract.archive_list!;
          this.contract.archive_list!.forEach((x) => {
            x.archive_type_str = this.model.Archive.TYPE[x.archive_type];
            x.is_confirmed_str = x.is_confirmed ? 'Yes' : 'No';
          });
        });
      },
      editArchive(mode, item?) {
        const vm = this;
        const isAdd = mode === 'add';
        const archiveTypeItems = this.getModelItems(this.model.Archive.TYPE);
        const countryList = this.countryList?.map((c) => ({ text: c.name, value: c.id }));
        const defaultCountry = this.contract.company?.country_id;
        const languageItems = this.getModelItems(this.model.Archive.LANGUAGE);
        let fileItems: any[] = this.contract.lark_contracts.map((x) => ({
          text: `${x.contract_number}.pdf`,
          value: x.scan_file_path,
        }));
        const hasFileStateList = this.contract.flow.state_list.filter((x) => x.file_list.length > 0);
        const flowFileItems: any[] = [];
        hasFileStateList.forEach((state) => {
          state.file_list.forEach((f: any) => {
            flowFileItems.push({ text: f.file_name, value: f.file_path });
          });
        });
        if (flowFileItems.length > 0) {
          fileItems = fileItems.concat(flowFileItems);
        }

        const inputs = {
          type: 'input',
          value: [
            {
              type: 'text',
              label: '名称',
              result: 'archive_name',
              rules: [
                {
                  required: true,
                  type: 'any',
                  trigger: 'change',
                  message: '请输入名称',
                },
              ],
              width: 24,
            },
            {
              type: 'select',
              label: 'Archive Type',
              result: 'archive_type',
              items: archiveTypeItems,
              width: 24,
              rules: [
                {
                  required: true,
                  type: 'any',
                  trigger: 'change',
                  message: '请选择',
                },
              ],
            },
            {
              type: 'select',
              label: '国家',
              result: 'country_id',
              items: countryList,
              width: 24,
              rules: [
                {
                  required: true,
                  type: 'number',
                  trigger: 'change',
                  message: '请选择国家',
                },
              ],
              init: defaultCountry,
            },
            {
              type: 'select',
              label: '语言',
              result: 'language',
              items: languageItems,
              rules: [required()],
              width: 24,
            },
            {
              type: 'select',
              label: '附件',
              result: 'files',
              items: fileItems,
              init: (isEmptyData(item) ? [] : item.files || []).map((x) => x.name),
              rules: [required()],
              mode: 'multiple',
              width: 24,
            },
            {
              type: 'text',
              label: '位置',
              result: 'location',
              width: 24,
            },
          ],
        };
        const title = mode === 'delete' ? 'Delete Archive' : isAdd ? '新增档案' : '编辑档案';
        const dialogConfig: DialogConfig = {
          title: title,
          width: '600px',
          init: isAdd ? null : item,
          labelCol: '100px',
          blocks: [],
          callback: (result) => {
            const meta = { method: mode, entity: 'Archive', entity_id: isAdd ? 0 : item.id };
            let data = {};
            if (isAdd) {
              data = {
                contract_id: vm.contract.id,
                ...result,
                files: fileItems
                  .filter((f) => result.files.includes(f.value))
                  .map((f) => ({ name: f.text, path: f.value })),
              };
            }
            manage_entity({ meta: meta, data: data }).then(() => {
              appStore.SET_WE_DIALOG(null);
              this.initData();
              // vm.traceAction(title);
            });
          },
        };

        if (mode === 'delete') {
          dialogConfig.blocks?.push({
            type: 'tip',
            value: 'Are you sure to delete this Archive?',
            width: 24,
          });
        } else {
          dialogConfig.blocks?.push(inputs);
        }

        vm.$showDialog(dialogConfig);
      },
      downloadTemplate(item) {
        add_download_contract_template_log({ contract_id: this.contract.id, template_id: item.id });
        this.downloadUrl(item.rd_file_url);
      },
      showDownloadTemplateLog() {
        const items = this.contract.download_log || [];
        items.sort((b, a) => a['time'].localeCompare(b['time']));
        this.$showAlert('Download Log', {
          config: {
            useSearchForm: false,
            immediate: false,
            showTableHeader: false,
            columns: [
              { title: 'Time', dataIndex: 'time' },
              { title: 'User', dataIndex: 'user_name' },
              { title: 'Template', dataIndex: 'template_name' },
            ],
          },
          items: items,
        });
      },
      async setBulines() {
        this.initData();
        this.show_edit_bulines = true;
      },
      removeBuline(buline) {
        this.bulineSplitList = this.bulineSplitList.filter((x) => x.id != buline.id);
      },
      saveBulines() {
        change_contract_buline({ contract_id: this.contract.id, bulines: this.bulineSplitList }).then(() => {
          this.show_edit_bulines = false;
          this.initData();
        });
      },
      getAmOptions(team_id) {
        if (isEmptyData(team_id)) {
          return [];
        } else {
          return this.getTeamUsers(team_id);
        }
      },
      addInvoice() {
        const initInvoice = {
          we_company_id: this.contract?.we_company_id,
          contract: this.contract,
          company_id: this.contract.company_id,
          is_china: this.contract.company.region_type === this.model.WeCompany.REGION_TYPE.CHINA,
          date: formatTime(Date(), 'YYYY-MM-DD'),
          we_tax_number: this.contract.we_company.tax_number,
          we_address: this.contract.we_company.address,
          we_phone: this.contract.we_company.phone,
          cs_tax_number: this.contract.company.tax_number,
          cs_address: this.contract.company.invoice_address,
          cs_phone: this.contract.company.invoice_phone,
          is_special: false,
          is_prepay: this.contract.contract_property === this.model.Contract.CONTRACT_PROPERTY.PREPAY,
        };
        const pageConfig = {
          pathSuffix: '',
          type: this.contract.contract_type,
        };
        if (this.contract.contract_type === this.model.Contract.DIRECTION.DEMAND) {
          pageConfig.pathSuffix = 'demand';
          initInvoice.is_special = initInvoice.is_china;
        } else {
          pageConfig.pathSuffix =
            this.contract.category === this.model.Contract.CATEGORY.PURCHASE ? 'purchase' : 'supply';
        }
        this.editInvoice(this, 'add', pageConfig, initInvoice, []);
      },
      addCfi() {
        const company = this.contract.company;
        const bank = this.contract.bank;
        const initData = {
          country_id: company.country_id,

          country_state: company.country_state,
          tax_number: company.tax_number,
          invoice_address: company.invoice_address,
          invoice_phone: company.invoice_phone,

          bank_address: bank.bf_bank_address,
          bank_swift_code: bank.bf_swift_code,
          bank_name: bank.bf_bank,
          bank_account_number: bank.bf_account_number,
        };
        const countryItems = sortBy(this.countryList, (c) => {
          return !['CN', 'HK', 'SG', 'US', 'JP'].includes(c.code);
        }).map((c) => ({ name: c.code + ' - ' + c.name, id: c.id }));
        const cnId = this.countryList.find((x) => x.code === 'CN')!.id;
        const usId = this.countryList.find((x) => x.code === 'US')!.id;
        const isGlobal = (result) => result.country_id && result.country_id !== cnId;
        const isCN = (result) => result.country_id === cnId;
        const isUS = (result) => result.country_id === usId;
        this.$showDialog({
          title: '新建客商信息申请',
          init: initData,
          labelCol: '100px',
          blocks: [
            {
              type: 'input',
              value: [
                { type: 'auto-select', result: 'country_id', label: '地区', items: countryItems },
                { type: 'text', label: '州', result: 'country_state', show: isUS },
                { type: 'text', label: '公司地址', result: 'invoice_address', show: isGlobal },
                { type: 'text', label: '银行名称', result: 'bank_name', show: isGlobal },
                { type: 'text', label: '银行账号', result: 'bank_account_number', show: isGlobal },
                { type: 'text', label: '银行地址', result: 'bank_address', show: isGlobal },
                { type: 'text', label: 'Swift Code', result: 'bank_swift_code', show: isGlobal },

                { type: 'text', label: '纳税人识别号', result: 'tax_number', show: isCN },
                { type: 'text', label: '公司电话', result: 'invoice_phone', show: isCN },
                { type: 'text', label: '公司地址', result: 'invoice_address', show: isCN },
                { type: 'text', label: '开户行', result: 'bank_name', show: isCN },
                { type: 'text', label: '开户行账号', result: 'bank_account_number', show: isCN },
              ],
            },
            {
              type: 'tip',
              show: isCN,
              value:
                '<div style="margin-left: 100px;">' +
                `
提示：国内客户或供应商，请从合同文本或业务沟通记录中提取如下信息，完整填写开票信息，要素如下
  1. 客户/供应商主体：
  2. 客户/供应商税号：
  3. 客户/供应商地址：
  4. 客户/供应商电话：
  5. 客户/供应商银行和账号信息：
填写过程中任何问题，可以联系业务单元FBP 或 财务部蔡雨凌。
`.trim() +
                '</div>',
            },
            {
              type: 'tip',
              show: isGlobal,
              value:
                '<div style="margin-left: 100px;">' +
                `
提示：海外客户或供应商，请从合同文本或业务沟通记录中提取信息，完整填写客户/供应商注册国家和地址信息，要素如下
  1. 海外客户：录入客户公司所在国和 客户公司地址。
  2. 海外供应商：
    a. 供应商公司所在国、供应商公司地址
    b. 银行名称、银行账号、银行地址、Swift Code
填写过程中任何问题，可以联系业务单元FBP 或 财务部蔡雨凌。
`.trim() +
                '</div>',
            },
          ],
          callback: (result) => {
            edit_company_finance_info_apply({ contract_id: this.contract.id, data: result }).then((res) => {
              this.afterEditContract(res);
              this.jumpToCfiDetail({ id: res });
            });
          },
        });
      },
      async editCompany() {
        const companyItems = (
          await get_company_list({
            get_all: true,
            company_group_id: this.contract.company.company_group_id,
            pick_fields: ['id', 'name'],
          })
        ).company_list.map((c) => ({ text: c.name, value: c.id }));
        this.$showDialog({
          title: '修改主体',
          init: this.contract,
          labelCol: '100px',
          blocks: [
            {
              type: 'input',
              value: [
                {
                  type: 'auto-select',
                  result: 'company_id',
                  label: '主体',
                  items: companyItems,
                  rules: [
                    {
                      required: true,
                      type: 'any',
                      trigger: 'change',
                      message: '请选择',
                    },
                  ],
                },
              ],
            },
          ],
          callback: (result) => {
            let params = { __meta: { edit_mode: 'edit', contract_id: this.contract.id }, ...result };
            edit_contract(params).then((result) => {
              this.afterEditContract(result);
            });
          },
        });
      },
      downloadArchiveAttachFile(file_name, file_path) {
        return window.open(`${this.apiRoot}/get_oss_file/?file_name=${file_name}&file_path=${file_path}`, '_blank');
      },
    },
  });
